body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*
Sunlight valley lake mountains
https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Fnature&psig=AOvVaw0TA17yMl-YdXytbAg8KbPx&ust=1653054849170000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCOC7rrrb6_cCFQAAAAAdAAAAABAX


Wooden bridge trees scenary lake walk
https://images.unsplash.com/photo-1543877087-ebf71fde2be1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fG5hdHVyYWx8ZW58MHx8MHx8&w=1000&q=80

Sunlight rays trees forest
https://wallpaperaccess.com/full/36293.jpg
*/